import { MetaSwiper, SafariSwiper } from "./meta-settings.js";
import Swiper from "./plugins/swiper-bundle.esm.browser.min.js";
import AOS from "https://cdn.jsdelivr.net/npm/aos@2.3.4/+esm";

AOS.init({
  startEvent: "load",
  disableMutationObserver: false,
  duration: 600,
  once: true,
});
AOS.refresh(true);

const header = document.getElementsByClassName(`header`)[0];

const vh = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};

vh();

window.onresize = vh;

// Header scroll
const scrollContainer = () => {
  return document.documentElement || document.body;
};

if (header.classList.contains("fixed")) {
  document.addEventListener("scroll", () => {
    if (scrollContainer().scrollTop > 0) {
      header.classList.add("scrolled");
    } else if (scrollContainer().scrollTop == 0) {
      header.classList.remove("scrolled");
    }
  });
}

// menu handlers

///check mobile menu show/hide condition
const mobileMenuStartPoint = +getComputedStyle(
  document.documentElement
).getPropertyValue("--mobile-menu-start-point");
let isMobileMenuEnable =
  $(window).outerWidth() <= mobileMenuStartPoint || $(".header-mobile").length;

$(".dropdown-toggle").click(function (e) {
  if (isMobileMenuEnable) {
    //close all opened sub menu
    $(".menu-item.dropdown.active .dropdown-menu").slideUp({
      complete: function () {
        $(this).closest(".dropdown").removeClass("active");
      },
    });

    //open current submenu
    $(this).closest(".menu-item.dropdown").toggleClass("active");
    if ($(this).closest(".menu-item.dropdown").hasClass("active")) {
      e.preventDefault();
      $(this).next(".dropdown-menu").slideDown();
    }
  }
});

// Cards hover
VanillaTilt.init(document.querySelectorAll("[data-tilt]"));

// Scroll button
const scrollButton = document.querySelector(".hero-screen__scroll");
if (scrollButton) {
  const heroScreen = document.querySelector(".hero-screen");
  function scrollProgress() {
    let winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    let height = heroScreen.scrollHeight;
    let scrolled = (winScroll / height) * 100;
    scrollButton.querySelector(".fill").style.height = scrolled + "%";
  }
  window.onscroll = function () {
    scrollProgress();
  };
}

// toggle menu handler
function menuToggle() {
  $(".menu-toggle").toggleClass("active");
  $(".mobile-header").toggleClass("active");
  $(".header-close-wrapper").toggleClass("active");
  // LockScroll when burger open and enable when closed and enable scroll on menu
  scrollLock.getScrollState()
    ? scrollLock.disablePageScroll(document.querySelector(".navbar-nav .menu"))
    : scrollLock.enablePageScroll();
}
//menu update function
function updateMenu() {
  isMobileMenuEnable =
    $(window).outerWidth() <= mobileMenuStartPoint ||
    $(".mobile-header").length;
  if (!isMobileMenuEnable) {
    $(".dropdown-menu").css("display", "");
    $(".header-close-wrapper").removeClass("active");
    $(".menu-item.active").removeClass("active");
    $(".navbar-nav").removeClass("active");
    $(".menu-toggle").removeClass("active");
    // LockScroll when burger open and enable when closed
    scrollLock.enablePageScroll();
  }
}
$(window).on("resize orientationchange", updateMenu);
// end of toggle menu handler

$(".menu-toggle").click(menuToggle); //menu toggles
$(".header-close-wrapper").click(menuToggle); //menu toggles

// Hide/Show
const accordion = document.querySelectorAll(".acc-content");
if (accordion.length) {
  const showBtn = document.querySelectorAll(".acc-trigger");

  accordion.forEach((acc, i) => {
    if (showBtn[i].classList.contains("value-btn") && acc.scrollHeight < 155) {
      showBtn[i].classList.add("hidden");
    }

    if (showBtn[i].classList.contains("show-more") && acc.scrollHeight < 115) {
      showBtn[i].classList.add("hidden");
    }

    showBtn[i].addEventListener("click", () => {
      showBtn[i].classList.toggle("open");

      const title = showBtn[i].querySelector(".title");

      if (showBtn[i].classList.contains("open")) {
        acc.style.maxHeight = acc.scrollHeight + "px";

        if (title) title.textContent = "Show Less";
      } else {
        acc.style.maxHeight = "";
        if (title) title.textContent = "Show More";
      }
    });
  });
}

/* ----- Sliders ----- */

// Testimonials
const testimonialsSection = document.querySelector(".clients-section");
if (testimonialsSection) {
  const testimonialsSlider = new Swiper(".testimonials-swiper", {
    slidesPerView: 1,
    spaceBetween: 12,
    speed: 600,
    grabCursor: true,
    rewind: true,

    effect: "creative",
    creativeEffect: {
      limitProgress: 8,
      prev: {
        translate: [0, 0, -400],
        opacity: 0,
      },
      next: {
        translate: ["calc(100% + 12px)", 0, 0],
        opacity: 1,
      },
    },

    // Autoplay
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },

    // Navigation
    navigation: {
      nextEl: ".clients-section .navigation-next",
      prevEl: ".clients-section .navigation-prev",
    },

    breakpoints: {
      551: {
        spaceBetween: 20,

        creativeEffect: {
          limitProgress: 8,
          prev: {
            translate: [0, 0, -400],
            opacity: 0,
          },
          next: {
            translate: ["calc(100% + 20px)", 0, 0],
            opacity: 1,
          },
        },
      },
    },
  });

  SafariSwiper(testimonialsSlider);
}

// Contact images slider
const contactSlider = document.querySelector(".contact-slider");
if (contactSlider) {
  const swiper = new Swiper(contactSlider, {
    slidesPerView: 1,
    grabCursor: true,
    effect: "fade",

    fadeEffect: {
      crossFade: true,
    },

    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
    },

    // Autoplay
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
  });

  SafariSwiper(swiper);
}

// Partners Swiper
const partnersSection = document.querySelector(".partners-section");
if (partnersSection) {
  const partnersSlides = partnersSection.querySelectorAll(".swiper-slide");

  const partnersSlider = new Swiper(".partners-swiper", {
    spaceBetween: 35,
    slidesPerView: "auto",
    observer: true,
    speed: 400,
    grabCursor: true,
    loop: partnersSlides.length > 5,

    // Autoplay
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },

    // Breakpoints
    breakpoints: {
      // when window width is >= 650px
      650: {
        // slidesPerView: 4,
        spaceBetween: 42,
      },
      // when window width is >= 769px
      769: {
        // slidesPerView: 5,
        spaceBetween: 50,
      },

      1024: {
        // slidesPerView: "auto",
        spaceBetween: 75,
      },
    },
  });

  SafariSwiper(partnersSlider);
}

// Similar Swiper
const similarStudiesSection = document.querySelector(".similar-section");
if (similarStudiesSection) {
  const partnersSlider = new Swiper(".case-studies-slider", {
    spaceBetween: 20,
    slidesPerView: "auto",
    observer: true,
    speed: 400,

    // Autoplay
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
  });

  SafariSwiper(partnersSlider);
}
